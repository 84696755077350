import React from 'react';
import { Container, Typography, Paper, List, ListItem, ListItemText } from '@mui/material';
import '../styles/Users.css';

const Users = () => {

  // Dummy data for the example, you can replace this with real API data
  const users = [
    { id: 1, name: 'John Doe' },
    { id: 2, name: 'Jane Doe' },
    { id: 3, name: 'Emily' },
    // ... more users
  ];

  return (
    <Container>
      <Typography variant="h4" sx={{ margin: '20px 0' }}>
        Users
      </Typography>
      <Paper sx={{ padding: 4 }}>
        <List>
          {users.map((user) => (
            <ListItem key={user.id}>
              <ListItemText primary={user.name} />
            </ListItem>
          ))}
        </List>
      </Paper>
    </Container>
  );
};

export default Users;
