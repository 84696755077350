import React from 'react';
import { makeStyles } from '@mui/styles';
import logo from '../assets/logo.jpg';  // Importing the logo

const useStyles = makeStyles({
  header: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 10, // Higher z-index to ensure header appears on top
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 20px',
    backgroundColor: '#333',
    color: '#fff',
  },
  logo: {
    width: '60px',
  },
  title: {
    fontSize: '24px',
  },
  logoutButton: {
    backgroundColor: '#ff4d4d',
    color: '#fff',
    padding: '10px 20px',
    border: 'none',
    cursor: 'pointer',
  },
});

const Header = () => {
  const classes = useStyles();

  return (
    <header className={classes.header}>
      <img src={logo} alt="Logo" className={classes.logo} />
      <div className={classes.title}>Geeth Stories Admin</div>
      <button className={classes.logoutButton} onClick={() => alert('Logging out...')}>
        Logout
      </button>
    </header>
  );
};

export default Header;
