import React from 'react';
import { Container, Typography, Paper, List, ListItem, ListItemText } from '@mui/material';
import '../styles/Orders.css';

const Orders = () => {
  
  // Dummy data for example, you would typically fetch this from an API
  const orders = [
    { id: 1, item: 'Custom Frame', status: 'Shipped' },
    { id: 2, item: 'Digital Portrait', status: 'In Progress' },
    { id: 3, item: 'Pencil Sketch', status: 'Completed' },
    // ... more orders
  ];

  return (
    <Container>
      <Typography variant="h4" sx={{ margin: '20px 0' }}>
        Orders
      </Typography>
      <Paper sx={{ padding: 4 }}>
        <List>
          {orders.map((order) => (
            <ListItem key={order.id}>
              <ListItemText 
                primary={`Item: ${order.item}`} 
                secondary={`Status: ${order.status}`} 
              />
            </ListItem>
          ))}
        </List>
      </Paper>
    </Container>
  );
};

export default Orders;
