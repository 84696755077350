import React from 'react';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Dashboard from './components/Dashboard';
import Users from './components/Users';
import Orders from './components/Orders';
import Products from './components/Products';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <Header />
      <div style={{ display: 'flex', marginTop: '60px' }}>  {/* Add margin-top */}
        <Sidebar />
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/users" element={<Users />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/products" element={<Products />} />
          <Route path="/" element={<Dashboard />} /> {/* Redirect to dashboard */}
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
