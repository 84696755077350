import React from 'react';
import '../styles/Dashboard.css';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  content: {
    marginLeft: '260px', // The width of the sidebar
    padding: '20px',
    marginTop: '120px',  // Make it start below the header,
    zIndex: 2, // Making sure it's above the sidebar and below the header
    backgroundColor: 'white', // Adding a background color to make it visible
    position: 'relative' // To ensure zIndex applies
  },
});

const Dashboard = () => {
  const classes = useStyles();
  return (
    <div className={classes.content}>
      <div className="dashboard-text">
        Welcome to the Dashboard!!
      </div>
    </div>
  );
}

export default Dashboard;
