import React from 'react';
import { Container, Typography, Paper, List, ListItem, ListItemText } from '@mui/material';
import '../styles/Products.css';

const Products = () => {

  // Dummy data for demonstration purposes; typically, you would fetch this from an API
  const products = [
    { id: 1, name: 'Digital Portrait', price: '$50' },
    { id: 2, name: 'Pencil Sketch', price: '$30' },
    { id: 3, name: 'Gift Hamper', price: '$100' },
    // ... more products
  ];

  return (
    <Container>
      <Typography variant="h4" sx={{ margin: '20px 0' }}>
        Products
      </Typography>
      <Paper sx={{ padding: 4 }}>
        <List>
          {products.map((product) => (
            <ListItem key={product.id}>
              <ListItemText 
                primary={`Name: ${product.name}`} 
                secondary={`Price: ${product.price}`} 
              />
            </ListItem>
          ))}
        </List>
      </Paper>
    </Container>
  );
};

export default Products;
